<template>
  <div>
    <v-app-bar
      app color="success" height="55"
      dense
      flat
      rounded="lg"
      shaped
      class="mx-2"
    >
      <!-- Boton que solo aparece para la version movil para abrir el menu -->
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-md-none"
        ></v-app-bar-nav-icon>

      <!--Icono de Home  -->
      <div>
        <v-img  :src="require ('../assets/img/LOGO-BLANCO.png')" width="150px" height="50px" class="mr-6" contain></v-img>
      </div>

      <!-- Menu  -->
      <div class="text-center d-none d-md-flex" v-if=" !$store.state.AuthModule.rol?.read">
        <div v-for="(menu, i) in itemsMenu" :key="i">
          <v-menu
            v-model="allMenuName[menu.name]"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            nudge-bottom="10"
            content-class="rounded-xl"
          >
            <template v-slot:activator="{ on, attrs }">
              <a
                class="mx-3 white--text text-caption"
                v-bind="attrs"
                v-on="on"
                @click="openBaseWindow(menu.action)"
              >
                <strong  >{{menu.title}}</strong>
              </a>
            </template>

            <v-card v-if="!menu.action">
              <v-container>
                <v-list
                  nav
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItem"
                    color="blue-grey darken-1"
                  >
                    <v-list-item
                      v-for="(item, i) in menu.items"
                      :key="i"
                      @click="openBaseWindow(item.action)"
                    >
                      <!-- <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                      </v-list-item-icon> -->

                      <v-list-item-content>
                        <v-list-item-title>{{item.text}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-container>
            </v-card>
          </v-menu>
        </div>
      </div>

      <v-spacer></v-spacer>
      <v-avatar>
        <!-- <img
          class="mr-3"
          src="https://cdn.vuetifyjs.com/images/john.jpg"
          alt="John"
        > -->
        <v-icon color="grey">mdi-database-outline</v-icon>
      </v-avatar>
      <v-toolbar-title class="white--text mr-5 caption" v-if="breakStatus != 'xs'"
        >{{$store.state.AuthModule.comdes}}
      </v-toolbar-title>

      <v-avatar>
        <!-- <img
          class="mr-3"
          src="https://cdn.vuetifyjs.com/images/john.jpg"
          alt="John"
        > -->
        <v-icon>mdi-account</v-icon>
      </v-avatar>
      <v-toolbar-title class="white--text mr-5 caption" v-if="breakStatus != 'xs'"
        >{{$store.state.AuthModule.name}}
      </v-toolbar-title>

      <v-btn icon>
        <v-icon @click="logout">mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Responseve Menu -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
        >
          <v-list-item v-for="(item, index) in itemsMenu" :key="index">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'NavBar',
  data: () => ({
    allMenuName: {
      menu: false,
      menu1: false,
      menu2: false,
    },
    selectedItem: 0,
    itemsMenu: [
      { name: 'menu',
        title: 'Facturación',
        items: [
          { text: 'Factura', icon: 'mdi-folder', action: 'facturaViewListState' },
          { text: 'Tiquete', icon: 'mdi-account-multiple', action: 'facturaTiqueteListState' },
          { text: 'Nota de crédito', icon: 'mdi-star', action: 'facturaNotaListState' },
          { text: 'Factura de Compra', icon: 'mdi-history', action: 'facturaCompraListState' },
          { text: 'Factura de Contingencia', icon: 'mdi-check-circle', action: 'facturaContigenciaListState' }
        ]
      },
      { name: 'menu1',
        title: 'Recepción',
        items: [
          { text: 'Facturas carga Manual', icon: 'mdi-folder', action: 'facturaCargaManual'},
          { text: 'Facturas carga Automática', icon: 'mdi-account-multiple', action: 'facturaCargaAuto' },
        ]
      },
      { name: 'menu2',
        title: 'Proformas',
        action: 'facturaProformaListState'
      },
      // { name: 'menu3',
      //   title: 'Cuentas x Cobrar',
      //   items: [
      //     { text: 'Facturas', icon: 'mdi-folder' },
      //     { text: 'Rpt de Recibos', icon: 'mdi-account-multiple' },
      //     { text: 'Antiguedad de Saldos', icon: 'mdi-star' },
      //   ]
      // },

      { name: 'menu5',
        title: 'Catálogos',
        items: [
          { text: 'Actividades', icon: 'mdi-folder', action: 'catalogActivity'},
          { text: 'Articulos', icon: 'mdi-account-multiple', action: 'catalogArticulos' },
          { text: 'Clientes', icon: 'mdi-star', action: 'catalogClientes' },
          { text: 'Vendedores', icon: 'mdi-history', action: 'catalogVendedores' },
        ]
      },
      { name: 'menu4',
        title: 'Reportes',
        items: [
          { text: 'Documentos Emitidos', icon: '', action: 'reporteDocRangoFecha' },
          // { text: 'Doc Emitidos by user', icon: 'mdi-account-multiple' },
          { text: 'Borrador para IVA', icon: 'mdi-star', action: 'reporteIva'  },
          { text: 'Resumen de Compras', icon: 'mdi-history', action: 'reporteCompras'},
          { text: 'Resumen de Compas IVA', icon: 'mdi-history', action: 'reporteComprasIva'},
          // { text: 'Facturacion', icon: 'mdi-check-circle' },
          { text: 'Facturación y artículo tildado', icon: 'mdi-check-circle', action: 'reporteVentasxArt' },
          { text: 'Auxiliar compra y venta', icon: 'mdi-check-circle', action: 'reporteAuxiliarCompraVenta'},
        ]
      },
      { name: 'menu6',
        title: 'Manual PDF',
        action: 'downloadManualPDF'
      },
      { name: 'menu7',
        title: 'Ayuda',
        items: [
          { text: 'Version', icon: 'mdi-folder', action: 'versionEfac'},
          { text: 'Cambiar Contraseña', icon: 'mdi-account-multiple', action: 'changePass' },]
      }
    ],
    drawer: false
  }),
  methods: {
    openBaseWindow(item){
      if (item === 'downloadManualPDF') this.$emit('download-manual-pdf')
      this.$store.state[item] = true
      localStorage.setItem('selectOption', item)
    },
    logout(){
      this.$store.dispatch("AuthModule/logoutUser")
      this.$store.dispatch("actResetState")
      this.$router.push({ name: "LoginView" })
    }
  },
  computed: {
    breakStatus() {
      const bs = this.$vuetify.breakpoint.name;
      return bs;
    },
  },
}
</script>

<style></style>