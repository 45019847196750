<template>
  <v-app style="background-image: linear-gradient(to bottom, #ECEFF1, #FFFFFF);">
    <nav-bar class="la-navbar " v-if="$route.name == 'HomeView'" v-on:download-manual-pdf="downloadPdf" ></nav-bar>
    <v-main class="mx-2 mt-2 mb-3">
      <router-view/>
    </v-main>

    <v-row dense>
      <v-col cols="12">
        <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Por favor espera...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { axiosbd } from "@/api/axios-bd";
import { saveAs } from "file-saver";
import NavBar from '@/components/NavBar.vue'
import { generalMixing } from "@/mixins/general";
export default {
  name: 'App',
  mixins:[generalMixing],
  components: {
      NavBar,
  },
  data: () => ({
    dialog: false
  }),
  methods:{
    async downloadPdf(){
      this.dialog = true
      const body = {
          company: this.company,
          ref: this.$store.state.AuthModule.r2,
        };

        await this.$store.dispatch("AuthModule/verifyToken")

        axiosbd({
          url: `factura/descarga_manual_pdf/`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            'Content-Type': 'application/json',
          },
          data: body,
          responseType: 'arraybuffer',
        })
        .then((response) => {
          // console.log(response.status)
          saveAs(new Blob([response.data]), `manual efacweb v1.pdf`)
          this.showAlert('success', 'El manual de usuario ha sido descargado correctamente. Puede acceder a él en la ventana emergente que se abrió. Si no ve la ventana emergente, también puede encontrar el manual en su carpeta de descargas predeterminada.');

          this.dialog = false
        })
        .catch((err)=>{
          this.dialog = false
          try {
            const textDecoder = new TextDecoder('utf-8');
            const errorMessage = textDecoder.decode(err.response.data);
            this.showAlert('error', errorMessage);
          } catch (error) {
            this.showAlert('error','Error del sistema, si persiste contactar al soporte tecnico')
          }
        })
    }
  }
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  /* color: #546E7A; */
  font-family:  "Open Sans",sans-serif;
}
/* html {
  overflow: hidden;
  overflow-y: hidden;
} */

/* .home-view{
  overflow: auto;
} */

.v-application a {
  color: #546E7A !important;
  text-decoration: none !important;
}
.text--color{
  color: #546E7A;
}

.theme--light.v-input input{
  color: #546E7A;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 22px !important;
  padding: 0 10px !important;
}


/* Colores a los botones en las alertas */
.colorCncel{
  background-color: #fa0000 !important;
  border-color: #fa0000 !important;
  margin-left: 0.5rem;
}

.colorConfirm{
  background-color: #0063b4 !important;
  border-color: #0063b4 !important;
  margin-right: 0.5rem;
}

/* Estilo para los formularios todos en modo dense */
.custom-form-text-fields .v-input__control .v-text-field__details {
  margin-bottom: 4px;
}
.border-cabys{
  border: solid thin #546E7A
}

.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 5px !important;
}

.custom-form-text-fields .v-input__control .v-text-field__details {
  margin-bottom: 0 !important;
}

.custom-form-text-fields .v-input__control .v-input__slot .v-text-field__slot,
.custom-form-text-fields .v-input__control .v-input__slot .v-select__slot{
  font-size: 0.7rem !important;
}

.custom-form-text-fields .v-input__control .v-input__slot .v-text-field__slot .v-label--active.theme--light,
.custom-form-text-fields .v-input__control .v-input__slot .v-select__slot .v-label--active.theme--light{
  font-size: 15px !important;
}

.custom-form-text-fields .v-input__control .v-input__slot .v-text-field__slot .theme--light,
.custom-form-text-fields .v-input__control .v-input__slot .v-select__slot .theme--light{
  font-size: 13px !important;
}


.v-text-field--outlined.v-input--dense .v-label {
  top: 6px !important;
}

.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer {
  margin-top: 4px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 2px !important;
}
.v-tabs-bar {
  height: 23px !important;
}

.v-tab {
  text-transform: capitalize !important;
}

.v-subheader {
  height: 32px !important;
  font-size: 0.75rem !important;
}

/* .custom-form-text-fields .v-input__control .v-input__slot .v-text-field__slot .v-label{
  font-size: 15px !important;
} */
/* .v-input input {
  max-height: 28px !important;
} */

.v-input--switch.v-input__control.v-input__slot{
  margin: 0 !important;
}

.v-input--switch .v-input__control .v-input__slot label{
  font-size: 12px !important;
}

.no-mensaje-box .v-input__control .v-text-field__details{
  display: none !important;
}

.dialog-factura{
  max-height: 97% !important;
}

/* .v-label {
  font-size: 15px !important;
} */


/* INPUTS DE SOLO NUMEROS CON FORMATO NUMERICO */
.input-totals .v-input__control .v-text-field__details{
  display: none !important;
}
.input-totals .v-input__control .v-input__slot .v-text-field__slot{
  font-size: 12px !important;
}
.input-totals .v-input__control .v-input__slot{
  padding: 0 0.5rem 0 0.5rem !important;
}
.input-totals .v-input__control .v-input__slot input {
  text-align: end !important;
  max-height: 28px !important;
}

/* DE LOS SELECT */
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 32px !important;
}



/* SWEET ALERT */

.swal2-popup {
    font-size: 11px !important;
}

.swal2-styled {
    padding: 10px 32px 10px 32px !important;
    margin: 20px 10px 0px 10px !important;
    width: 170px;
    height: 45px;
}
</style>
