import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import vuetify from './plugins/vuetify'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.use(VueSweetalert2);

Vue.prototype.$convertToMoney = function (x) {
  if (x) {
    x = Number(x)
    return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return '0.00'
}


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
