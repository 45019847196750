import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/Home',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassView.vue')
  },
  {
    path: '/erro',
    name: 'ErrorView',
    meta: {
      message: 'La URL o dirección solicitada no se encuentra en el servidor.Al presionar regresar ira al login de la aplicacion.'
    },
    component: () => import('../views/Error.vue') 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const res = await Store.dispatch('AuthModule/verifyToken')
  if (to.name !== 'ResetPassView' && to.name !== 'ErrorView'){
    if (to.name !== 'LoginView') {
      if (res.state) {
        next()
      } else {
        // console.log(res.message+" r34")
        // router.history.push('/')
        router.history.push({name: 'LoginView'})
      }
    } else {
      if (res.state) {
        router.history.push({name: 'HomeView'})
      } else {
        // console.log('no hay res', res)
        // router.history.push({name: 'LoginView'})
        next()
      }
    }
  } else {
    if (to.name == 'ResetPassView'){
      // console.log(to)
      const res = await Store.dispatch('AuthModule/verifyPassToken', to.query)
      if (res.state){
        next()
      } else {
        // console.log('DEBO MOSTRAR LA PAGINA DE ERROR')
        Store.state.mensajeError = 'El enlace para cambiar la contraseña esta vencido por favor solicitar uno nuevamente.'
        router.history.push({
          name: 'ErrorView',
          meta: {
            message: 'El enlace para cambiar la contraseña esta vencido por favor solicitar uno nuevamente.'
          }
        })
      }
      // console.log(res)
      //VALIDACIONES AL TOKEN
      //1. se valida el tiempo
      //2. se valida que el token no haya sido utilizado anteriormente
    } else {
      next()
    }
  }
})

export default router
