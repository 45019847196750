export const generalMixing = {
    methods: {
      showAlert(tipoMensaje, mensaje){
        const Toast = this.$swal.mixin({
          toast: false,
          position: 'top',
          showConfirmButton: true,
          // timer: 20000,
          // timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })
  
        Toast.fire({
          icon: tipoMensaje,
          title: mensaje
        })
      },
      errorAlert(err){
        let mensaje = "Se presentan los siguientes errores:\n";
        if ('response' in err){
            let errores = err.response.data ?? null
            // console.log('ERRORES', errores)
            if (errores.constructor == String){
              mensaje += errores
            } else {
              for (const campo in errores) {
                // eslint-disable-next-line no-prototype-builtins
                if (errores.hasOwnProperty(campo)) {
                  const mensajesDeError = errores[campo];
                  if (Array.isArray(mensajesDeError)) {
                    mensaje += `${campo}: ${mensajesDeError.join(", ")}\n`;
                  } else {
                    mensaje += `${campo}: ${mensajesDeError}\n`;
                  }
                }
              }
            }
            this.showAlert('error', mensaje)
          } else {
            if (err.message == "Network Error") {
              // console.log('error')
              this.showAlert('error', 'error de red, verificar su conexion a internet')
            } else if (err == "Error: timeout of 1000ms exceeded") {
              this.showAlert('error', 'Se excedio el tiempo de espera, intentar nuevamente')
            } else {
              if (mensaje.length){
                // console.log(mensaje)
                this.showAlert('error', mensaje)
              }
              // else {
              //   this.showAlert('error', err.message)
              // }
            }
          }
      },
      spValidateErrorMessage(data, nameLoading){
        for (let item of data){
          // console.log('ITEM RETURN CODE', item)
          if ('return_code' in item){
            if (item.return_code == 0) {
              this[nameLoading] = false
              this.showAlert(
                "success",
                `${item.return_code} ${item.return_code_msg}`,
                // duration: 7500,
              )
              return true
            } else {
              throw new Error(
                `${item.return_code} ${item.return_code_msg}`
              )
            }
          }
        }
      },
  
      spShowErrorMessage(err, nameLoading){
        this[nameLoading] = false
        if (err == "Error: Network Error") {
          this.showAlert(
            "error",
            `Se encontro un problema: Error de conexion a internert, favor revisar su conexion a internet.`,
          )
        } else if (err == "Error: timeout of 1000ms exceeded") {
          this.showAlert(
            "error",
            `Se encontro un problema: ${err + " Revisar Conexion DB"}`,
          );
        } else {
          this.showAlert(
            "error",
            `Se encontro un problema: ${
              err.response
                ? err.response.data.message
                : err.message
                ? err.message
                : err
            }`,
          );
        }
      },
      async getDataGeneral(
        method, url, nameItemStore, nameLoading, moreBody = {}, sp = false, params = {},
        pagination = false, returnSome = false, comcod_asig = false, one = false){
        // console.log(moreBody, params)
        this[nameLoading] = true

        let returnResult = []
        await this.$store.dispatch('AuthModule/verifyToken')

        let body = {
          company: this.company,
          ref: this.$store.state.AuthModule.r2,
          params: {...params}
        }

        if (comcod_asig){
          body.comcod_asignado =  this.company
        }

        const obj = {
          token : this.$store.state.AuthModule.accessToken,
          method: method,
          url: url,
          body: {...body, ...moreBody}
        }
        // this.loadingTable = true
        await this.$store.dispatch('fechData', obj)
        .then((result) => {
          // console.log(result)

          if (returnSome){
            // console.log('DEBO RETORNAR VALOR')
            if (one){
              returnResult =  {...result.data}
            } else {
              returnResult =  [...result.data]
            }
          }

          if (pagination){
            this.totalPages = result.totalPages
          }

          if (nameItemStore){
            // console.log('NAMESTORE', nameItemStore)
            this[nameLoading] = false
            this.$store.dispatch('actItemsBaseWindowsList',{data: result.data, nombre: nameItemStore})
          } else {
            this[nameLoading] = false
            return 200
          }

        })
        .catch((err) => {
          // console.log(err)
          this[nameLoading] = false
          if (err.response.status == 401){
            this.showAlert( 'error',
              `token no valido o vencido:  ${err.response.data.detail}`
            )
            this.$router.push({name: 'LoginView'})
          } else if (err.response.status == 404){
            this.showAlert( 'error', 'Pagina no encontrada')
          } else if (err == "Error: Network Error") {
            this.showAlert(
              "error",
              `Se encontro un problema: Error de conexion a internert, favor revisar su conexion a internet.`,
            )
          } else if (err == "Error: timeout of 1000ms exceeded") {
            this.showAlert(
              "error",
              `Se encontro un problema: ${err + " Revisar Conexion DB"}`,
            );
          } else {
            if (sp){
              if ('response' in err){
                this.errorAlert(err)
              } else {
                this.spShowErrorMessage(err)
              }
            } else {
              this.errorAlert(err)
            }
          }
        })
        return returnResult
      },
      async summit(method, url, body, loadingName, getMethod =false, getUrl=false, itemStore=false, moreBody,sp = false, closeNameFunction = false, showResponseAlert = false ){
        this[loadingName] = true
        await this.$store.dispatch('AuthModule/verifyToken')
        const obj = {
          token : this.$store.state.AuthModule.accessToken,
          method: method,
          url: url,
          body: body
        }

        await this.$store.dispatch('fechData', obj)
        .then((result) => {
          // console.log(typeof result.data, result.data)

          if (result.data.constructor === Array) {
            // console.log('ARRAY',result.data)
            if (showResponseAlert) this.spValidateErrorMessage(result.data, '')
          } else {
            // console.log('RESPUESTA',result.data)
            if (showResponseAlert) this.showAlert('success', result.data)
          }
         
          if (closeNameFunction){
            if (typeof this[closeNameFunction] === 'function') {
              this[closeNameFunction]()
            } else {
                throw new Error('La función ' + closeNameFunction + ' no existe o no es una función.')
            }
          }

          if (getMethod) this.getDataGeneral(getMethod, getUrl, itemStore, loadingName,moreBody, sp)

        })
        .catch((err) => {
          // console.log('ERROR',err)
          this[loadingName] = false
          if (err.response.status == 401){
            this.showAlert( 'error',
              `token no valido o vencido:  ${err.response.data.detail}`
            )
            this.$router.push({name: 'LoginView'})
          } else if (err.response.status == 404){
            this.showAlert( 'error', 'Pagina no encontrada')
          } else if (err == "Error: Network Error") {
            this.showAlert(
              "error",
              `Se encontro un problema: Error de conexion a internert, favor revisar su conexion a internet.`,
            )
          } else if (err == "Error: timeout of 1000ms exceeded") {
            this.showAlert(
              "error",
              `Se encontro un problema: ${err + " Revisar Conexion DB"}`,
            );
          } else {
            if (sp){
              if ('response' in err){
                this.errorAlert(err)
              } else {
                this.spShowErrorMessage(err)
              }
            } else {
              this.errorAlert(err)
            }
          }
        })
        
      },
      async getDataGeneral2(
        method, url, nameItemStore, nameLoading, moreBody = {},
        pagination = false, returnSome = false, normal= false, urlParams, one=false, paramsAxios={}) {
        // console.log(moreBody, params)
        this[nameLoading] = true

        let returnResult = []
        await this.$store.dispatch('AuthModule/verifyToken')

        const obj = {
            token: this.$store.state.AuthModule.accessToken,
            method: method,
            url: urlParams ? url + '?' +  urlParams.toString() : url,
            body: { ...moreBody },
            params: paramsAxios ? paramsAxios : null
        }
        // this.loadingTable = true
        // console.log(obj)
        await this.$store.dispatch('fechData2', obj)
            .then((result) => {

                if (returnSome && !pagination) {
                    if(normal){
                        if (one){
                            returnResult =  {...result}
                        } else {
                            returnResult =  [...result]
                        }
                    } else {
                        returnResult = [...result.results]
                    }
                }

                if (pagination) {
                    this.totalPages = result.total_pages
                    returnResult = [...result.results]
                }

                if (nameItemStore) {
                    this[nameLoading] = false
                    
                    if(normal){
                        // console.log('DEVOLVIENDO {}')
                        // console.log(result)
                        this[nameItemStore] =  [...result]
                    } else if (!normal && !one) {
                        this[nameItemStore] =  [...result.results]
                    } else if (one){
                        this[nameItemStore] = [{...result}]
                    }
                    // this.$store.dispatch('actItemsBaseWindowsList', { data: result.data, nombre: nameItemStore })
                } else {
                    this[nameLoading] = false
                    return 200
                }

            })
            .catch((err) => {
                // console.log(err)
                this[nameLoading] = false
                if (err.response.status == 401) {
                    this.showAlert('error',
                        `token no valido o vencido:  ${err.response.data.detail}`
                    )
                    this.$router.push({ name: 'LoginView' })
                } else if (err.response.status == 404) {
                    this.showAlert('error', 'Pagina no encontrada')
                } else if (err == "Error: Network Error") {
                    this.showAlert(
                        "error",
                        `Se encontro un problema: Error de conexion a internert, favor revisar su conexion a internet.`,
                    )
                } else if (err == "Error: timeout of 1000ms exceeded") {
                    this.showAlert(
                        "error",
                        `Se encontro un problema: ${err + " Revisar Conexion DB"}`,
                    );
                } else {
                    this.errorAlert(err)
                }
            })
        return returnResult
    },
    },
  }